.calendar-picker {
    position: relative;
}

.calendar-container {
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1050;
}

.calendar-container .calendar {
    position: relative;
    width: 280px;
    text-align: left;
    list-style: none;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.calendar-container .calendar-input-wrap {
    height: 34px;
    padding: 6px 10px;
    border-bottom: 1px solid #e8e8e8;
}

.calendar-container .calendar-input {
    width: 100%;
    height: 22px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border: 0;
    outline: 0;
    cursor: auto;
}

.calendar-container .calendar-header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
}

.calendar-container .calendar-header span:hover {
    color: #40a9ff;
    cursor: pointer;
}

.calendar-container .calendar-month-select {
    display: inline-block;
    padding: 0 2px;
    font-weight: 500;
    line-height: 40px;
}

.calendar-container .calendar-header .calendar-century-select,
.calendar-container .calendar-header .calendar-decade-select,
.calendar-container .calendar-header .calendar-year-select,
.calendar-container .calendar-header .calendar-month-select {
    display: inline-block;
    padding: 0 2px;
    font-weight: 500;
    line-height: 40px;
}

.calendar-container .calendar-prev-month-btn,
.calendar-container .calendar-next-month-btn,
.calendar-container .calendar-prev-year-btn,
.calendar-container .calendar-next-year-btn,
.calendar-container .calendar-month-panel-prev-year-btn,
.calendar-container .calendar-month-panel-next-year-btn,
.calendar-container .calendar-year-panel-prev-decade-btn,
.calendar-container .calendar-year-panel-next-decade-btn,
.calendar-container .calendar-decade-panel-prev-century-btn,
.calendar-container .calendar-decade-panel-next-century-btn {
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 0 5px;
    font-size: 0;
    border: none;
    background-color: transparent;
}

.calendar-container .calendar-prev-month-btn::before,
.calendar-container .calendar-next-month-btn::before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #555;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(0.8);
    transition: all 0.3s;
    content: "";
}

.calendar-container .calendar-prev-month-btn {
    left: 27px;
}

.calendar-container .calendar-next-month-btn {
    right: 27px;
}

.calendar-container .calendar-next-month-btn::before {
    transform: rotate(135deg) scale(0.8);
}

.calendar-container .calendar-prev-year-btn::before,
.calendar-container .calendar-next-year-btn::before,
.calendar-container .calendar-month-panel-prev-year-btn::before,
.calendar-container .calendar-month-panel-next-year-btn::before,
.calendar-container .calendar-year-panel-prev-decade-btn::before,
.calendar-container .calendar-year-panel-next-decade-btn::before,
.calendar-container .calendar-decade-panel-prev-century-btn::before,
.calendar-container .calendar-decade-panel-next-century-btn::before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #555;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(0.8);
    transition: all 0.3s;
    content: "";
}

.calendar-container .calendar-prev-year-btn::after,
.calendar-container .calendar-next-year-btn::after,
.calendar-container .calendar-month-panel-prev-year-btn::after,
.calendar-container .calendar-month-panel-next-year-btn::after,
.calendar-container .calendar-year-panel-prev-decade-btn::after,
.calendar-container .calendar-year-panel-next-decade-btn::after,
.calendar-container .calendar-decade-panel-prev-century-btn::after,
.calendar-container .calendar-decade-panel-next-century-btn::after {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #555;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    transform: rotate(-45deg) scale(0.8);
    transition: all 0.3s;
    content: "";
}

.calendar-container .calendar-prev-year-btn,
.calendar-container .calendar-month-panel-prev-year-btn,
.calendar-container .calendar-year-panel-prev-decade-btn,
.calendar-container .calendar-decade-panel-prev-century-btn {
    left: 7px;
}

.calendar-container .calendar-prev-year-btn::after,
.calendar-container .calendar-month-panel-prev-year-btn::after,
.calendar-container .calendar-year-panel-prev-decade-btn::after,
.calendar-container .calendar-decade-panel-prev-century-btn::after {
    left: -3px;
}

.calendar-container .calendar-next-year-btn,
.calendar-container .calendar-month-panel-next-year-btn,
.calendar-container .calendar-year-panel-next-decade-btn,
.calendar-container .calendar-decade-panel-next-century-btn {
    right: 7px;
}

.calendar-container .calendar-next-year-btn::before,
.calendar-container .calendar-month-panel-next-year-btn::before,
.calendar-container .calendar-year-panel-next-decade-btn::before,
.calendar-container .calendar-decade-panel-next-century-btn::before {
    right: -3px;
    transform: rotate(135deg) scale(0.8);
}

.calendar-container .calendar-next-year-btn::after,
.calendar-container .calendar-month-panel-next-year-btn::after,
.calendar-container .calendar-year-panel-next-decade-btn::after,
.calendar-container .calendar-decade-panel-next-century-btn::after {
    transform: rotate(135deg) scale(0.8);
}

.calendar-container .calendar-body {
    padding: 8px 12px;
}

.calendar-container .calendar table,
.calendar-container .calendar th,
.calendar-container .calendar td {
    text-align: center;
    border: 0;
}

.calendar-container .calendar table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
}

.calendar-container .calendar-cell {
    height: 30px;
    padding: 3px 0;
}

.calendar-container .calendar-date {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    line-height: 22px;
    text-align: center;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    transition: background 0.3s ease;
}

.calendar-container .calendar-date:hover {
    background: #e6f7ff;
    cursor: pointer;
}

.calendar-container .calendar-selected-day .calendar-date {
    background: #bae7ff;
}

.calendar-container .calendar-year-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none;
}

.calendar-container .calendar-year-panel-year {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    text-align: center;
    background: transparent;
    border-radius: 2px;
    transition: background 0.3s ease;
}

.calendar-container .calendar-year-panel-year:hover {
    background: #e6f7ff;
    cursor: pointer;
}

.calendar-container .calendar-year-panel-selected-cell .calendar-year-panel-year {
    color: #fff;
    background: #1890ff;
}

.calendar-container .calendar-month-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none;
}

.calendar-container .calendar-month-panel-selected-cell .calendar-month-panel-month {
    color: #fff;
    background: #1890ff;
}

.calendar-container .calendar-month-panel-month {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    text-align: center;
    background: transparent;
    border-radius: 2px;
    transition: background 0.3s ease;
}

.calendar-container .calendar-month-panel-month:hover {
    background: #e6f7ff;
    cursor: pointer;
}

.calendar-container .calendar-decade-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    outline: none;
}

.calendar-container .calendar-decade-panel-decade {
    display: inline-block;
    height: 24px;
    margin: 0 auto;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    text-align: center;
    background: transparent;
    border-radius: 2px;
    transition: background 0.3s ease;
}

.calendar-container .calendar-decade-panel-decade:hover {
    background: #e6f7ff;
    cursor: pointer;
}

.calendar-container .calendar-decade-panel-selected-cell .calendar-decade-panel-decade {
    color: #fff;
    background: #1890ff;
}

.calendar-container .calendar-last-month-cell .calendar-date,
.calendar-container .calendar-next-month-btn-day .calendar-date,
.calendar-container .calendar-last-month-cell .calendar-date:hover,
.calendar-container .calendar-next-month-btn-day .calendar-date:hover {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border-color: transparent;
}